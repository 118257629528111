import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { Link } from 'gatsby'


const SuccessPage = props => (
  <Layout color='bg-indigo-800'>
    <SEO title='Success' />
    <div className='py-40 container mx-auto max-w-3xl px-6 sm:px-8 lg:px-8 text-center'>
      <div className='title'><h1>Success!</h1></div>
      <p className='text'>Thanks for the message, we will get back to you within 24 hours! <span className=''>(Unless it's the weekend, then we'll be in touch Monday)</span></p>
      <Link to='/' className='button mt-4' href='/'>Take me home</Link>
    </div>
  </Layout>
)

export default SuccessPage
